import React, {useEffect, useState} from 'react'
import Dropdown from '../Dropdown'
import ResponsiveNavLink, {ResponsiveNavButton,} from '../ResponsiveNavLink'
import DropdownLink, {DropdownButton} from '../DropdownLink'
import {useAuth} from '../../hooks/auth'
import i18next, {t} from 'i18next'
import Swal from 'sweetalert2'
import {useStateContext} from '../../context/ContextProvider'
import {Link, useHref, useNavigate} from 'react-router-dom'
import NavLink from '../NavLink'
import {Tooltip} from '@mui/material'
import PalletContacts from '../customer_portal/PalletContacts'
import RequestShipments from '../customer_portal/RequestShipments'
import RequestCollection from '../customer_portal/RequestCollection'
import axios from '../../lib/axios'
import {isValid} from '../../helpers/helper'
import { includes } from 'lodash'
import UserSignatureModal from '../driver/UserSignatureModal'
import OrderSignatureModal from '../driver/OrderSignatureModal'

const Navigation = ({ user }) => {

    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    const router = useHref()

    const { logout, logoutImpersonate } = useAuth()
    const { sites, allSites, choosesite, setChoosesite, company_id, setCompany_id, getCurrency, config, pusher, performedFirstTimeRequestRetrieval, setPerformedFirstTimeRequestRetrieval } = useStateContext()

    const [open, setOpen] = useState(false)
    const [openResDailyBoardButton, setOpenResDailyBoardButton] = useState(false)
    const [openResSettingsButton, setOpenResSettingsButton] = useState(false)

    {/* Customer Portal Responsive Menus */ }
    const [openResDashboardsButton, setOpenResDashboardsButton] = useState(false)
    const [openResRequestButton, setOpenResRequestButton] = useState(false)
    const [openResActivityButton, setOpenResActivityButton] = useState(false)

    const [flag, setFlag] = useState(i18next.resolvedLanguage)
    const [selectedSite, setSelectedSite] = useState(localStorage.getItem('site'))
    const [show, setShow] = useState(false)
    const [hasFailedToDeimpersonate, setHasFailedToDeimpersonate] = useState(false)
    const [insightUser, setInsightUser] = useState('')
    const [total, setTotal] = useState(0)

    const [userHasSignature, setUserHasSignature] = useState(user?.has_signature ?? false)
    const [userSignatureModalOpen, setUserSignatureModalOpen] = useState(false)

    const [status, setStatus] = useState([])
    const [errors, setErrors] = useState([])

    const [openInfo, setOpenInfo] = useState(false)

    const [focusedResMenuItem, setFocusedResMenuItem] = useState(router)

    useEffect(() => {
        if(isValid(company_id)) {
            const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${localStorage.getItem('company_id')}`)
            const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${localStorage.getItem('company_id')}`)
            const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${localStorage.getItem('company_id')}`)

            const channeldelete1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${localStorage.getItem('company_id')}`)
            const channelcreate1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${localStorage.getItem('company_id')}`)
            const channelupdate1 = pusher.subscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${localStorage.getItem('company_id')}`)

            channeldelete.bind(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-event-company-${localStorage.getItem('company_id')}`, data => {
                getCustomerRequests(data.company_id)
            })

            channelcreate.bind(`${localStorage.getItem('client_id')}-shipmentrequest-created-event-company-${localStorage.getItem('company_id')}`, data => {
                getCustomerRequests(data.company_id)
            })

            channelupdate.bind(`${localStorage.getItem('client_id')}-shipmentrequest-updated-event-company-${localStorage.getItem('company_id')}`, data => {
                getCustomerRequests(data.company_id)
            })

            channeldelete1.bind(`${localStorage.getItem('client_id')}-collectionrequest-deleted-event-company-${localStorage.getItem('company_id')}`, data => {
                getCustomerRequests(data.company_id)

            })

            channelcreate1.bind(`${localStorage.getItem('client_id')}-collectionrequest-created-event-company-${localStorage.getItem('company_id')}`, data => {
                getCustomerRequests(data.company_id)
            })

            channelupdate1.bind(`${localStorage.getItem('client_id')}-collectionrequest-updated-event-company-${localStorage.getItem('company_id')}`, data => {
                getCustomerRequests(data.company_id)
            })
        }

        return (() => {
            if(isValid(company_id)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-deleted-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-created-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipmentrequest-updated-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-deleted-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-created-company-${company_id}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-collectionrequest-updated-company-${company_id}`)
            }
        })
    }, [company_id])

    useEffect(() => {
        if (!open) {
            setOpenResActivityButton(false)
            setOpenResDailyBoardButton(false)
            setOpenResDashboardsButton(false)
            setOpenResRequestButton(false)
            setOpenResSettingsButton(false)
        }
    }, [open])

    const getResMenuItemBorderColor = (route) => {
        if (router === route && focusedResMenuItem === route) {
            return 'border-indigo-700'
        } else if (router === route && focusedResMenuItem != route) {
            return 'border-indigo-400'
        } else {
            return 'border-gray-300'
        }
    }

    const handleOpenInfo = () => {
        setOpenInfo(!openInfo)
    }

    const handleCloseInfo = () => {
        setOpenInfo(false)
    }

    const [openRS, setOpenRS] = useState(false)
    const [openRC, setOpenRC] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('showImpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('impersonation')}),
                text: t('you_are_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showImpersonationMessage')
            })
        } else if (localStorage.getItem('showDeimpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('deimpersonation')}),
                text: t('you_are_no_longer_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showDeimpersonationMessage')
                localStorage.removeItem('impersonatee_name')
            })
        }
        if (user?.role === 'customers_user') { getInsightsUser() }
        if (localStorage.getItem('company_id') && !performedFirstTimeRequestRetrieval) {
            setPerformedFirstTimeRequestRetrieval(true)
        }
    }, [])

    useEffect(() => {
        setCompany_id(localStorage.getItem('company_id'))
        setChoosesite(localStorage.getItem('site'))
    }, [])

    useEffect(() => {
        if (company_id) {
            getCustomerRequests(company_id)
        }
    }, [company_id])


    const getInsightsUser = async () => {
        await axios.get(`/api/insights-users/${user?.id}`, config)
            .then(res => {
                const user = res.data
                setInsightUser(user)
            })
    }

    const getCustomerRequests = async (cid) => {
        if (['master_admin', 'office_manager', 'customers_user'].includes(user?.role)) {
            let shipments = 0
            let collections = 0
            let url = `shipment-requests?customer_id=${user?.customer_id}&status=pending`

            if (user?.role === 'master_admin' || user?.role === 'office_manager') {
                url = `shipment-requests?company_id=${cid}&status=pending`
            }

            let url1 = `collection-requests?customer_id=${user?.customer_id}&status=pending`

            if (user?.role === 'master_admin' || user?.role === 'office_manager') {
                url1 = `collection-requests?company_id=${cid}&status=pending`
            }

            await axios.get(`/api/${url}`, config)
                .then(res => {
                    const shipment = res.data
                    shipments = shipment.length
                })
            await axios.get(`/api/${url1}`, config)
                .then(res => {
                    const collection = res.data
                    collections = collection.length
                })

            setTotal(shipments + collections)
        }
    }

    const handleOpenRS = () => {
        setOpenRS(!openRS)
    }

    const handleCloseRS = () => {
        setOpenRS(false)
    }

    const handleOpenRC = () => {
        setOpenRC(!openRC)
    }

    const handleCloseRC = () => {
        setOpenRC(false)
    }

    const logoutUser = () => {
        logout(includes(['tpl_driver', 'service_provider'], user?.role), user?.role)
    }

    const deimpersonateUser = async () => {
        logoutImpersonate({
            setErrors: setErrors,
            setStatus: setStatus,
            user: user
        })
    };

    const DropdownSignatureButton = () => {
        if(includes(["driver", "tpl_driver", "service_provider"], user?.role)) {
            return (
                <DropdownButton onClick={() => setUserSignatureModalOpen(true)}>
                    <i className="fa-solid fa-pen"></i>  {userHasSignature ? t('view_your_signature') : t('add_your_signature')}
                </DropdownButton>
            )
        }

        return null
    }

    const DropdownLogoutButton = () => {
        if (localStorage.getItem('impersonate') === null) {
            return (
                <DropdownButton onClick={() => logoutUser()}>
                    <i className="fa-solid fa-right-from-bracket"></i> {t('logout')}
                </DropdownButton>
            )
        } else if (hasFailedToDeimpersonate) {
            return (
                <DropdownButton onClick={() => logoutUser()}>
                    <i className="fa-solid fa-triangle-exclamation mr-3" style={{ color: "#d5da0c" }}></i> {t('force_logout')}
                </DropdownButton>
            )
        } else {
            return (
                <DropdownButton onClick={() => deimpersonateUser(user?.role === 'customers_user', user?.customer_id)}>
                    {/* <i className="fa-solid fa-user-slash"></i> */} {t('leave_impersonation')}
                </DropdownButton>
            )
        }
    }

    const ResDropdownSignatureButton = () => {
        if(includes(["driver", "tpl_driver", "service_provider"], user?.role)) {
            return (
                <ResponsiveNavButton onClick={() => setUserSignatureModalOpen(true)}>
                    <div className="py-2.5 space-y-1">
                        <i className="fa-solid fa-pen mr-3"></i> {userHasSignature ? t('view_your_signature') : t('add_your_signature')}
                    </div>
                </ResponsiveNavButton>
            )
        }

        return null
    }

    const ResDropdownLogoutButton = () => {
        if (localStorage.getItem('impersonate') === null) {
            return (
                <ResponsiveNavButton onClick={() => logoutUser()}>
                    <div className="py-2.5 space-y-1">
                        <i className="fa-solid fa-right-from-bracket mr-3"></i> {t('logout')}
                    </div>
                </ResponsiveNavButton>
            )
        } else if (hasFailedToDeimpersonate) {
            return (
                <ResponsiveNavButton onClick={() => logoutUser()}>
                    <div className="py-2.5 space-y-1">
                        <i className="fa-solid fa-triangle-exclamation mr-3" style={{ color: "#d5da0c" }}></i> {t('force_logout')}
                    </div>
                </ResponsiveNavButton>
            )
        } else {
            return (
                <ResponsiveNavButton onClick={() => deimpersonateUser(user?.role === 'customers_user', user?.customer_id)}>
                    <div className="py-2.5 space-y-1">
                    {/* <i className="fa-solid fa-user-slash"></i> */} {t('leave_impersonation')}
                    </div>
                </ResponsiveNavButton>
            )
        }
    }

    const languageFlag = () => {
        switch (flag) {
            case 'es':
                return (
                    <img src="../assets/images/flags/es.png" alt="es" />
                )
            case 'de':
                return (
                    <img src="../assets/images/flags/de.png" alt="de" />
                )
            default:
                return (
                    <img src="../assets/images/flags/us.png" alt="us" />
                )
        }
    }

    const setSiteStore = (e) => {
        localStorage.setItem('site', e.target.value)
        localStorage.setItem('company_id', e.target.selectedOptions[0].attributes.company.value)
        setSelectedSite(e.target.value)
        if (Number(e.target.selectedOptions[0].attributes.company.value) != Number(company_id)) {
            getCustomerRequests(e.target.selectedOptions[0].attributes.company.value)
        }
        setCompany_id(e.target.selectedOptions[0].attributes.company.value)
        setChoosesite(e.target.value)
        getCurrency(e.target.selectedOptions[0].attributes.company.value)
    }

    useEffect(() => {
        checkUserSite()
    }, [allSites, company_id])


    const checkUserSite = () => {

        if (localStorage.getItem('impersonate') !== null) {
            if (
                //user?.role !== 'master_admin' &&
                user?.role !== 'office_manager' &&
                user?.role !== 'salesperson' &&
                user?.role !== 'client'
            ) {
                localStorage.setItem('site', user?.site_id)
                localStorage.setItem('company_id', user?.company_id)
            } else {
                localStorage.setItem('site', allSites[0]?.id)
                localStorage.setItem('company_id', allSites[0]?.company_id)
            }
            setChoosesite(localStorage.getItem('site'))
            setSelectedSite(localStorage.getItem('site'))
            setCompany_id(localStorage.getItem('company_id'))

            if (user?.company_id !== null && Number(user?.company_id) !== Number(company_id)) {
                getCurrency(user?.company_id)
            }
        }
    }

    function renderSiteHeaderInfo() {
        const siteId = Number(localStorage.getItem('site'))
        const site = allSites.find((target) => target.id === siteId)

        return (
            <div className='flex flex-col lg:flex-row lg:gap-2 xl:items-center'>
                <span className='font-black roboto text-sky-700 text-xl lg:text-2xl line-clamp-1'>{site?.name}</span>
                <span className='font-light roboto text-gray-500 text-xl lg:text-2xl line-clamp-1'>{site?.company?.name}</span>
            </div>
        )
    }

    function renderSiteHeaderInfoHamburgered() {
        return (
            <div className="grow block md:hidden">
                <select className='w-full border-gray-300 border-l-0 border-t-0 border-r-0'
                    name="choosesite" id="choosesite" value={selectedSite}
                    onChange={(e) => setSiteStore(e)}>
                    {allSites.map((item, index) => <option key={index} value={item.id}
                        company={item.company_id}>{item?.company?.name} {item.name}</option>)}
                </select>
            </div>
        )
    }

    function renderSiteHeaderInfoCustomerUser() {
        /* const siteId = Number(localStorage.getItem('site'))
        const site = allSites.find((target) => target.id === siteId) */
        const customerName = user?.customer_name;

        return (
            <div className='flex flex-col lg:flex-row lg:gap-2 xl:items-center'>
                <span className='font-black roboto text-sky-700 text-xl lg:text-2xl line-clamp-1'>{customerName}</span>
            </div>
        )
    }

    const userHasPermissions = (targets = []) => {
        return user.permissions?.some((permission) => targets.includes(permission.name))
    }

    return (
        <nav className="bg-white border-b border-gray-100 drop-shadow-md sticky z-50">
            {/* Primary Navigation Menu */}
            <div className="mx-auto flex justify-between px-4 sm:px-6 lg:px-8">
                <div className='flex items-center gap-5 grow'>
                    <div className='flex items-center gap-4'>
                        <img className='min-w-[66px]' src="../assets/images/logo_small.png" alt="logo" />
                        {/* <div className='flex gap-1'>
                            <span className="font-black roboto text-2xl text-sky-700">Pallet</span><p className="text-2xl text-gray-500 font-light roboto">Insights</p>
                        </div> */}
                    </div>

                    {user?.role !== 'master_admin' &&
                        user?.role !== 'office_manager' &&
                        user?.role !== 'salesperson' &&
                        user?.role !== 'client' &&
                        user?.role !== 'customers_user' ? (
                        <div className='grow md:grow-0 hidden md:block'>
                            {renderSiteHeaderInfo()}
                        </div>
                    ) : (
                        user?.role === "customers_user" ? (
                            <div className='grow md:grow-0 hidden md:block'>
                                {renderSiteHeaderInfoCustomerUser()}
                            </div>
                        )
                            :
                            <div className="grow md:grow-0 hidden md:block">
                                <select className='w-full border-gray-300 border-l-0 border-t-0 border-r-0'
                                    name="choosesite" id="choosesite" value={selectedSite}
                                    onChange={(e) => setSiteStore(e)}>
                                    {allSites.map((item, index) => <option key={index} value={item.id}
                                        company={item.company_id}>{/* {item?.company?.name} - */} {item.name}</option>)}
                                </select>
                            </div>
                    )}
                </div>

                <div className="flex justify-end h-16">
                    {/* Navigation Links */}

                    {user?.role !== 'master_admin' &&
                        user?.role !== 'office_manager' ? (
                        ''
                    ) : (

                        total > 0 ?
                            <Link to='/customer-requests'>
                                <div className="flex items-center pl-8 pr-2 lg:pr-8 text-[#6b7280] relative h-full">
                                    <i className="fa-solid fa-bell"></i>
                                    <div className='absolute bg-[#336195] right-[10px] top-[10px] rounded-full px-[6px] py-[1px] leading-none'>
                                        <p className='text-sm font-bold text-white'>{total}</p>
                                    </div>
                                </div>
                            </Link>
                            :
                            null
                    )}

                    {user?.role !== 'master_admin' &&
                        user?.role !== 'office_manager' ? (
                            ''
                        ) : (
                            <div className="hidden lg:flex lg:items-center gap-8 px-4 uppercase" style={{ background: router === '/admin-dashboard' ? "#f5f5f5" : '' }}>
                                <NavLink
                                    to="/admin-dashboard"
                                    active={router === '/admin-dashboard'}>
                                    {t('dashboard')}
                                </NavLink>
                            </div>
                        )
                    }

                    {user?.role !== 'master_admin' &&
                        user?.role !== 'office_manager' &&
                        user?.role !== 'dispatcher' &&
                        user?.role !== 'salesperson' &&
                        !userHasPermissions(['daily-board-view', 'daily-board-update']) ? (
                        ''
                    ) : (
                        <div
                            className="hidden lg:flex lg:items-center gap-8"
                            style={{
                                background: includes(['/sales-order', '/purchase-order', '/broker-transactions'], router) && "#f5f5f5"
                            }}
                        >
                            <Dropdown
                                sx={{ zIndex: '10' }}
                                align="right"
                                width="max-content"
                                trigger={
                                    <button className="roboto flex items-center font-medium text-sm text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out px-4 uppercase" style={{ height: '64px', textTransform: 'uppercase' }}>
                                        {t('daily_board')}
                                    </button>
                                }>
                                <DropdownLink to="/sales-order">
                                    <div className="flex items-center justify-start gap-3">
                                        <span style={{ width: '20px', transform: 'rotate(45deg)', color: '#336195' }} className="flex items-center justify-center">
                                            <i className="fa-solid fa-arrow-up-long"></i>
                                        </span>
                                        {t('sales_orders')}
                                    </div>
                                </DropdownLink>

                                <DropdownLink to="/purchase-order">
                                    <div className="flex items-center justify-start gap-3">
                                        <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                            <i className="fa-solid fa-arrow-up-long"></i>
                                        </span>
                                        {t('purchase_orders')}
                                    </div>
                                </DropdownLink>
                                {
                                    (
                                        includes(['master_admin', 'office_manager'], user?.role)
                                        || userHasPermissions(['daily-board-view', 'daily-board-update'])
                                    ) ? (
                                        <DropdownLink to="/broker-transactions">
                                            <div className="flex items-center justify-start gap-3">
                                            <span style={{ width: '20px', color: '#148c8cb3' }} className="flex items-center justify-center">
                                                <i className="fa-solid fa-repeat"></i>
                                            </span>
                                                {t('broker_transactions')}
                                            </div>
                                        </DropdownLink>
                                    ) : null
                                }
                            </Dropdown>
                        </div>
                    )}

                    {user?.role !== 'customers_user' ? (
                        ''
                    ) : (
                        <div className="hidden lg:flex lg:items-center gap-8"
                            style={{ background: router === '/shipments-dashboard' ? "#f5f5f5" : '' || router === '/collections-dashboard' ? "#f5f5f5" : '' }}>
                            <Dropdown
                                align="right"
                                width="48"
                                trigger={
                                    <button
                                        className="roboto flex items-center font-medium text-sm text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out px-4 uppercase"
                                        style={{ height: '64px', textTransform: 'uppercase' }}>
                                        {t('dashboards')}
                                    </button>
                                }>
                                <DropdownLink to="/shipments-dashboard">
                                    <div className="flex items-center justify-start gap-3">
                                        <span style={{ width: '20px', transform: 'rotate(45deg)', color: '#336195' }} className="flex items-center justify-center">
                                            <i className="fa-solid fa-arrow-up-long"></i>
                                        </span>
                                        {t('shipments_board')}
                                    </div>
                                </DropdownLink>

                                {insightUser?.user_is_vendor ?
                                    <DropdownLink to="/collections-dashboard">
                                        <div className="flex items-center justify-start gap-3">
                                            <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                                <i className="fa-solid fa-arrow-up-long"></i>
                                            </span>
                                            {t('collections_board')}
                                        </div>
                                    </DropdownLink>
                                    :
                                    null
                                }
                            </Dropdown>

                        </div>
                    )}

                    {user?.role !== 'customers_user' ? (
                        ''
                    ) : (
                        <div className="hidden lg:flex lg:items-center gap-8">
                            <Dropdown
                                align="right"
                                width="48"
                                trigger={
                                    <button
                                        className="roboto flex items-center font-medium text-sm text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out px-4 uppercase"
                                        style={{ height: '64px', textTransform: 'uppercase' }}>
                                        {t('request_service')}
                                    </button>
                                }>
                                <DropdownButton onClick={handleOpenRS}>
                                    <div className="flex items-center justify-start gap-3">
                                        <span style={{ width: '20px', transform: 'rotate(45deg)', color: '#336195' }} className="flex items-center justify-center">
                                            <i className="fa-solid fa-arrow-up-long"></i>
                                        </span>
                                        {t('request_delivery')}
                                    </div>
                                </DropdownButton>

                                {
                                    insightUser?.user_is_vendor ?
                                        <DropdownButton onClick={handleOpenRC}>
                                            <div className="flex items-center justify-start gap-3">
                                                <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                                    <i className="fa-solid fa-arrow-up-long"></i>
                                                </span>
                                                {t('request_collection')}
                                            </div>
                                        </DropdownButton>
                                        :
                                        null
                                }

                                <DropdownLink to="/requests">
                                    <div className="flex items-center justify-start gap-3">
                                        <span style={{ width: '20px', color: 'green' }} className="flex items-center justify-center">
                                            <i className="fa-solid fa-check"></i>
                                        </span>
                                        {t('my_requests')}
                                    </div>
                                </DropdownLink>

                            </Dropdown>
                        </div>
                    )}

                    <RequestShipments open={openRS} handleClose={handleCloseRS} userId={user?.id} />
                    <RequestCollection open={openRC} handleClose={handleCloseRC} userId={user?.id} />

                    {user?.role !== 'customers_user' ? (
                        ''
                    ) : (
                        <div className="hidden lg:flex lg:items-center gap-8">
                            <Dropdown
                                align="right"
                                width="48"
                                trigger={
                                    <button
                                        className="roboto flex items-center font-medium text-sm text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out px-4 uppercase"
                                        style={{ height: '64px', textTransform: 'uppercase' }}>
                                        {t('activity_details')}
                                    </button>
                                }>
                                <DropdownLink to="/proceeded-shipments">
                                    <div className="flex items-center justify-start gap-3">
                                        <span style={{ width: '20px', transform: 'rotate(45deg)', color: '#336195' }} className="flex items-center justify-center">
                                            <i className="fa-solid fa-arrow-up-long"></i>
                                        </span>
                                        {t('shipments')}
                                    </div>
                                </DropdownLink>

                                {
                                    insightUser?.user_is_vendor ?
                                        <DropdownLink to="/proceeded-collections">
                                            <div className="flex items-center justify-start gap-3">
                                                <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                                    <i className="fa-solid fa-arrow-up-long"></i>
                                                </span>
                                                {t('collections')}
                                            </div>
                                        </DropdownLink>
                                        :
                                        null
                                }



                            </Dropdown>
                        </div>
                    )}

                    {user?.role !== 'master_admin' &&
                        user?.role !== 'office_manager' &&
                        user?.role !== 'dispatcher' &&
                        !userHasPermissions(['map']) ? (
                        ''
                    ) : (
                        <div className="hidden lg:flex lg:items-center gap-8 px-4 uppercase" style={{ background: router === '/map' ? "#f5f5f5" : '' }}>
                            <NavLink
                                to="/map"
                                active={router === '/map'}>
                                {t('map')}
                            </NavLink>
                        </div>

                    )}

                    {user?.role !== 'master_admin' &&
                        user?.role !== 'office_manager' &&
                        user?.role !== 'dispatcher' &&
                        !userHasPermissions(['dispatch']) ? (
                        ''
                    ) : (
                        <div className="hidden lg:flex lg:items-center gap-8 px-4 uppercase" style={{ background: router === '/dispatch' ? "#f5f5f5" : '' }}>
                            <NavLink
                                to="/dispatch"
                                active={router === '/dispatch'}>
                                {t('dispatch')}
                            </NavLink>
                        </div>

                    )}
                    {user?.role !== 'driver'
                        && user?.role !== 'forklift_driver' ? (
                        ''
                    ) : (
                        <div className="hidden lg:flex lg:items-center gap-8 px-4 uppercase" style={{ background: includes(['/available-loads'], router) && "#f5f5f5" }}>
                            <NavLink
                                to="/available-loads"
                                active={router === '/available-loads'}>
                                {t('available_loads')}
                            </NavLink>
                        </div>
                    )}
                    {user?.role !== 'master_admin' &&
                        user?.role !== 'office_manager' ? (
                        ''
                    ) : (
                        <div className="hidden lg:flex lg:items-center gap-8 px-4 uppercase">
                            <NavLink
                                to="/reports"
                                active={router === '/reports'}>
                                {t('reports')}
                            </NavLink>
                        </div>
                    )}

                    {user?.role !== 'customers_user' ? (
                        ''
                    ) : (
                        <div className="flex items-center mr-4 lg:mr-0 gap-1">
                            <Dropdown
                                align="right"
                                width="48"
                                trigger={
                                    <button onClick={handleOpenInfo}
                                        className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out w-[40px] h-full justify-center">
                                        <div className="text-lg">
                                            <Tooltip title={t('pallet_contacts')} placement='bottom'>
                                                <i className="fa-solid fa-id-card-clip"></i>
                                            </Tooltip>
                                        </div>
                                    </button>
                                }>
                            </Dropdown>
                        </div>
                    )}

                    <PalletContacts open={openInfo} handleClose={handleCloseInfo} userId={user?.id} />

                    {/* Settings Dropdown */}
                    <div className="flex items-center mr-4 lg:mr-0 gap-1">

                        {user?.role !== 'master_admin' &&
                            user?.role !== 'office_manager' &&
                            !userHasPermissions(['production-order-history']) ? (
                            ''
                        ) : (
                            <Dropdown
                                align="left"
                                width="max-content"
                                trigger={
                                    <button className="flex items-center justify-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out w-[40px] h-full">
                                        <div className="text-lg">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </button>
                                }>

                                {/* SHIPMENTS */}
                                {user?.role !== 'master_admin' &&
                                    user?.role !== 'office_manager' ? (
                                    ''
                                ) : (
                                    <DropdownLink to="/shipments">
                                        <div className="flex items-center justify-start gap-3">
                                            <span style={{ width: '20px', transform: 'rotate(45deg)' }} className="flex items-center justify-center">
                                                <i className="fa-solid fa-arrow-up-long"></i>
                                            </span>
                                            {t('shipments')}
                                        </div>
                                    </DropdownLink>
                                )}

                                {/* COLLECTIONS */}
                                {user?.role !== 'master_admin' &&
                                    user?.role !== 'office_manager' ? (
                                    ''
                                ) : (
                                    <DropdownLink to="/collections">
                                        <div className="flex items-center justify-start gap-3">
                                            <span style={{ width: '20px', transform: 'rotate(225deg)' }} className="flex items-center justify-center">
                                                <i className="fa-solid fa-arrow-up-long"></i>
                                            </span>
                                            {t('collections')}
                                        </div>
                                    </DropdownLink>
                                )}

                                {/* COLLECTIONS */}
                                {user?.role !== 'master_admin' &&
                                    user?.role !== 'office_manager' &&
                                    !userHasPermissions(['production-order-history']) ? (
                                    ''
                                ) : (
                                    <DropdownLink to="/production-orders">
                                        <div className="flex items-center justify-start gap-3">
                                            <span style={{ width: '20px' }} className="flex items-center justify-center">
                                                <i className="fa-solid fa-arrow-up-right-dots"></i>
                                            </span>
                                            {t('production_order_history')}
                                        </div>
                                    </DropdownLink>
                                )}

                                {/* Broker */}
                                {user?.role !== 'master_admin' &&
                                    user?.role !== 'office_manager' ? (
                                    ''
                                ) : (
                                    <DropdownLink to="/admin-broker-transactions">
                                        <div className="flex items-center justify-start gap-3">
                                            <span style={{ width: '20px' }} className="flex items-center justify-center">
                                                <i className="fa-solid fa-repeat"></i>
                                            </span>
                                            {t('broker_transactions')}
                                        </div>
                                    </DropdownLink>
                                )}

                                {user?.role !== 'master_admin' &&
                                    user?.role !== 'office_manager' ? (
                                    ''
                                ) : (
                                    <DropdownLink to="/customer-requests">
                                        <div className="flex items-center justify-start gap-3">
                                            <span style={{ width: '20px', transform: 'rotate(225deg)' }} className="flex items-center justify-center">
                                                <i className="fa-solid fa-arrows-rotate"></i>
                                            </span>
                                            {t('customer_requests')}
                                        </div>
                                    </DropdownLink>
                                )}

                                {/* COMPANIES */}
                                {user?.role !== 'master_admin' ? (
                                    ''
                                ) : (
                                    <DropdownLink to="/companies">
                                        <div className="flex items-center justify-start gap-3">
                                            <span
                                                style={{ width: '20px' }}
                                                className="flex items-center justify-center">
                                                <i className="fa-solid fa-grip-vertical"></i>
                                            </span>
                                            {t('companies')}
                                        </div>
                                    </DropdownLink>
                                )}

                                {/* SITES */}
                                {user?.role !== 'master_admin' &&
                                    user?.role !== 'office_manager' ? (
                                    ''
                                ) : (
                                    <DropdownLink to="/sites">
                                        <div className="flex items-center justify-start gap-3">
                                            <span
                                                style={{ width: '20px' }}
                                                className="flex items-center justify-center">
                                                <i className="fa-solid fa-grip-vertical"></i>
                                            </span>
                                            {t('sites')}
                                        </div>
                                    </DropdownLink>
                                )}



                                {/* ITEMS */}
                                {user?.role !== 'master_admin' &&
                                    user?.role !== 'office_manager' ? (
                                    ''
                                ) : (
                                    <>
                                        <DropdownButton onClick={e => { e.preventDefault(); setShow(!show) }}>
                                            <div className="flex items-center justify-between">
                                                <div className='flex items-center justify-start gap-3'>
                                                    <span
                                                        style={{ width: '20px' }}
                                                        className="flex items-center justify-center">
                                                        <i className="fa-solid fa-list"></i>
                                                    </span>
                                                    {t('items')}
                                                </div>
                                                <div>{show ? <i className="fa-solid fa-angle-down"></i> : <i className="fa-solid fa-angle-right"></i>}</div>
                                            </div>
                                        </DropdownButton>
                                        {
                                            show ?
                                                <>
                                                    <DropdownLink to="/items">
                                                        <div className="flex items-center justify-start gap-3 pl-3">
                                                            <span
                                                                style={{ width: '20px' }}
                                                                className="flex items-center justify-center">
                                                                <i className="fa-solid fa-list"></i>
                                                            </span>
                                                            {t('list')}
                                                        </div>
                                                    </DropdownLink>
                                                    <DropdownLink to="/grade-codes">
                                                        <div className="flex items-center justify-start gap-3 pl-3" >
                                                            <span
                                                                style={{ width: '20px' }}
                                                                className="flex items-center justify-center">
                                                                <i className="fa-solid fa-graduation-cap"></i>
                                                            </span>
                                                            {t('grade_codes')}
                                                        </div>
                                                    </DropdownLink>
                                                    <DropdownLink to="/units-of-measure">
                                                        <div className="flex items-center justify-start gap-3 pl-3">
                                                            <span
                                                                style={{ width: '20px' }}
                                                                className="flex items-center justify-center">
                                                                <i className="fa-solid fa-ruler"></i>
                                                            </span>
                                                            {t('units_of_measure')}
                                                        </div>
                                                    </DropdownLink>
                                                </>
                                                : ''
                                        }
                                    </>
                                )}

                                {/* IMPORT */}
                                {user?.role !== 'master_admin' ? ('') : (
                                    <DropdownLink to="/import">
                                        <div className="flex items-center justify-start gap-3">
                                            <span
                                                style={{ width: '20px' }}
                                                className="flex items-center justify-center">
                                                <i className="fa-solid fa-wrench"></i>
                                            </span>
                                            {t('import')}
                                        </div>
                                    </DropdownLink>
                                )}

                            </Dropdown>
                        )}


                        {user?.role !== 'master_admin' &&
                            user?.role !== 'office_manager' &&
                            user?.role !== 'production_supervisor' ? (
                            ''
                        ) : (
                            <Dropdown
                                align="left"
                                width="max-content"
                                trigger={
                                    <button className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out w-[40px] h-full justify-center">
                                        <div className="ml-1 text-lg">
                                            <i className="fa-solid fa-users-gear"></i>
                                        </div>
                                    </button>
                                }>

                                {/* USERS */}
                                {user?.role === 'master_admin' ||
                                    user?.role === 'office_manager' ? (
                                    <DropdownLink to="/users">
                                        <div className="flex items-center justify-start gap-3">
                                            <span
                                                style={{ width: '20px' }}
                                                className="flex items-center justify-center">
                                                <i className="fa-solid fa-users"></i>
                                            </span>
                                            {t('users')}
                                        </div>
                                    </DropdownLink>
                                ) : (
                                    ''
                                )}


                                {/* CUSTOMERS */}
                                {user?.role === 'master_admin' ||
                                    user?.role === 'office_manager' ? (
                                    <DropdownLink to="/customers">
                                        <div className="flex items-center justify-start gap-3">
                                            <span
                                                style={{ width: '20px' }}
                                                className="flex items-center justify-center">
                                                <i className="fa-solid fa-users"></i>
                                            </span>
                                            {t('customers')}
                                        </div>
                                    </DropdownLink>
                                ) : (
                                    ''
                                )}


                                {/* VENDORS */}
                                {user?.role === 'master_admin' ||
                                    user?.role === 'office_manager' ? (
                                    <DropdownLink to="/vendors">
                                        <div className="flex items-center justify-start gap-3">
                                            <span
                                                style={{ width: '20px' }}
                                                className="flex items-center justify-center">
                                                <i className="fa-solid fa-users"></i>
                                            </span>
                                            {t('vendors')}
                                        </div>
                                    </DropdownLink>
                                ) : (
                                    ''
                                )}

                                {/* PRODUCTION EMPLOYEES */}
                                {user?.role === 'master_admin' ||
                                    user?.role === 'office_manager' ||
                                    user?.role === 'production_supervisor' ? (
                                    <DropdownLink to="/production-employees">
                                        <div className="flex items-center justify-start gap-3">
                                            <span
                                                style={{ width: '20px' }}
                                                className="flex items-center justify-center">
                                                <i className="fa-solid fa-users"></i>
                                            </span>
                                            {t('production_employees')}
                                        </div>
                                    </DropdownLink>
                                ) : (
                                    ''
                                )}

                                {/* CONTACTS */}
                                {user?.role === 'master_admin' ||
                                    user?.role === 'office_manager' ? (
                                    <DropdownLink to="/contacts">
                                        <div className="flex items-center justify-start gap-3">
                                            <span
                                                style={{ width: '20px' }}
                                                className="flex items-center justify-center">
                                                <i className="fa-solid fa-address-book"></i>
                                            </span>
                                            {t('contacts')}
                                        </div>
                                    </DropdownLink>
                                ) : (
                                    ''
                                )}
                            </Dropdown>
                        )}

                        {
                            (
                                !includes(['master_admin', 'office_manager', 'prodution_supervisor'], user?.role)
                                && !userHasPermissions(['production-orders-view', 'production-orders-update', 'production-data-entry'])
                            ) ? (
                            ''
                            ) : (
                                <Dropdown
                                    align="right"
                                    width="48"
                                    trigger={
                                        <button className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out w-[40px] h-full justify-center">
                                            <div className="ml-1 text-lg">
                                                <i className="fa-solid fa-screwdriver-wrench"></i>
                                            </div>
                                        </button>
                                    }>

                                    {/* PRODUCTION ORDER */}
                                    {
                                        (
                                            userHasPermissions(['production-orders-view', 'production-orders-update'])
                                            || includes(['master_admin', 'office_manager'], user?.role)
                                        ) ? (
                                            <DropdownLink to="/production-order-board">
                                                <div className="flex items-center justify-start gap-3">
                                                    <span
                                                        style={{ width: '20px' }}
                                                        className="flex items-center justify-center">
                                                        <i className="fa-solid fa-arrow-up-right-dots"></i>
                                                    </span>
                                                    {t('production_orders')}
                                                </div>
                                            </DropdownLink>
                                        ) : null
                                    }

                                    {/* ENTRY */}
                                    {
                                        (
                                            userHasPermissions(['production-data-entry'])
                                            || includes(['master_admin', 'office_manager', 'production_supervisor'], user?.role)
                                        ) ? (
                                            <DropdownLink to="/enter-production-data">
                                                <div className="flex items-center justify-start gap-3">
                                                    <span className="fa-stack" style={{ fontSize: '10.5px', width: '20px' }}>
                                                        <i className="fa-solid fa-square fa-stack-2x"></i>
                                                        <i className="fa-solid fa-pen fa-stack-1x fa-inverse"></i>
                                                    </span>
                                                    {t('entry')}
                                                </div>
                                            </DropdownLink>
                                        ) : null
                                    }

                                    {/* PRODUCTION TYPES */}
                                    {!includes(['master_admin', 'office_manager'], user?.role) ? (
                                        ''
                                    ) : (
                                        <DropdownLink to="/production-types">
                                            <div className="flex items-center justify-start gap-3">
                                                <span
                                                    style={{ width: '20px' }}
                                                    className="flex items-center justify-center">
                                                    <i className="fa-solid fa-user-group"></i>
                                                </span>
                                                {t('production_types')}
                                            </div>
                                        </DropdownLink>
                                    )}

                                    {/* PRODUCTION ITEMS */}
                                    {!includes(['master_admin', 'office_manager'], user?.role) ? (
                                        ''
                                    ) : (
                                        <DropdownLink to="/production-items">
                                            <div className="flex items-center justify-start gap-3">
                                                <span
                                                    style={{ width: '20px' }}
                                                    className="flex items-center justify-center">
                                                    <i className="fa-solid fa-list"></i>
                                                </span>
                                                {t('production_items')}
                                            </div>
                                        </DropdownLink>
                                    )}
                                </Dropdown>
                            )
                        }

                        {
                            (
                                !includes(['master_admin', 'office_manager', 'prodution_supervisor'], user?.role)
                                && !userHasPermissions(['inventory-sheets-view', 'inventory-sheets-update'])
                            ) ? (
                                ''
                            ) : (
                                <Dropdown
                                    align="right"
                                    width="48"
                                    trigger={
                                        <button className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out w-[40px] h-full justify-center">
                                            <div className="ml-1 text-lg">
                                                <i className="fa-solid fa-warehouse"></i>
                                            </div>
                                        </button>
                                    }>

                                    {/* ENTER COUNTS */}
                                    {user?.role !== 'master_admin' &&
                                        user?.role !== 'office_manager' &&
                                        user?.role !== 'production_supervisor' &&
                                        !userHasPermissions(['inventory-sheets-update']) ? (
                                        ''
                                    ) : (
                                        <DropdownLink to="/enter-counts">
                                            <div className="flex items-center justify-start gap-3">
                                                <span className="fa-stack" style={{ fontSize: '10.5px', width: '20px' }}>
                                                    <i className="fa-solid fa-square fa-stack-2x"></i>
                                                    <i className="fa-solid fa-pen fa-stack-1x fa-inverse"></i>
                                                </span>
                                                {t('enter_counts')}
                                            </div>
                                        </DropdownLink>
                                    )}

                                    {/* COUNTS DETAILS */}
                                    {user?.role !== 'master_admin' &&
                                        user?.role !== 'office_manager' &&
                                        user?.role !== 'production_supervisor' &&
                                        !userHasPermissions(['inventory-sheets-view', 'inventory-sheets-update']) ? (
                                        ''
                                    ) : (
                                        <DropdownLink to="/inventory-sheets">
                                            <div className="flex items-center justify-start gap-3">
                                                <span
                                                    style={{ width: '20px' }}
                                                    className="flex items-center justify-center">
                                                    <i className="fa-solid fa-list"></i>
                                                </span>
                                                {t('counts_details')}
                                            </div>
                                        </DropdownLink>
                                    )}
                                </Dropdown>
                            )
                        }


                        <Dropdown
                            align="right"
                            width="48"
                            className='hidden lg:block'
                            trigger={
                                <button className="flex pr-[10px] pl-[10px] items-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out h-full justify-center">
                                    <div className='flex gap-2 items-center'>
                                        <div className='text-white bg-gray-500 rounded-full' style={{ fontSize: '10px', padding: '1px 8px' }}>
                                            <i className="fa-solid fa-user"></i>
                                        </div>
                                        {user?.name}
                                    </div>
                                    <div className="ml-1">
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </div>
                                </button>
                            }>
                            {/* Authentication */}
                            {DropdownSignatureButton()}
                            {DropdownLogoutButton()}
                        </Dropdown>

                        <Dropdown
                            align="right"
                            width="48"
                            trigger={
                                <button className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out w-[60px] h-full justify-center">
                                    {languageFlag()}
                                    <div className="ml-3">
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </div>
                                </button>
                            }>
                            {/* Authentication */}
                            <DropdownButton
                                onClick={() => {
                                    i18next.changeLanguage('en')
                                    setFlag('en')
                                }}>
                                <div className="flex gap-2 justify-start items-center">
                                    <img src="../assets/images/flags/us.png" alt='us' />
                                    {t('english')}
                                </div>
                            </DropdownButton>
                            <DropdownButton
                                onClick={() => {
                                    i18next.changeLanguage('es')
                                    setFlag('es')
                                }}>
                                <div className="flex gap-2 justify-start items-center">
                                    <img src="../assets/images/flags/es.png" alt='es' />
                                    {t('spanish')}
                                </div>
                            </DropdownButton>
                            {/* <DropdownButton
                                onClick={() => {
                                    i18next.changeLanguage('de')
                                    setFlag('de')
                                }}>
                                <div className="flex gap-2 justify-start items-center">
                                    <img src="../assets/images/flags/de.png" alt='de' />
                                    {t('germany')}
                                </div>
                            </DropdownButton> */}
                        </Dropdown>
                    </div>



                    {/* Hamburger */}
                    <div className="-mr-2 ml-2 flex items-center lg:hidden">
                        <button
                            onClick={() => setOpen(open => !open)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                            <svg
                                className="h-6 w-6"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24">
                                {open ? (
                                    <path
                                        className="inline-flex"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                ) : (
                                    <path
                                        className="inline-flex"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                )}
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Responsive Navigation Menu */}
            {open && (
                <div className="block lg:hidden">
                    {/* Responsive Site - Company information */}
                    <div className="pb-0 border-b border-gray-200">
                        <div className="flex md:hidden items-center px-4 pb-4 justify-between">
                            {user?.role !== 'master_admin' &&
                                user?.role !== 'office_manager' &&
                                user?.role !== 'dispatcher' &&
                                user?.role !== 'salesperson' ? (
                                <>
                                    {renderSiteHeaderInfo()}
                                </>
                            ) : (
                                <>
                                    {renderSiteHeaderInfoHamburgered()}
                                </>
                            )}
                        </div>
                    </div>

                    {/* Dashboard */}
                    {
                        (
                            includes(['master_admin', 'office_manager'], user?.role)
                        ) ? (
                            <ResponsiveNavLink
                                to="/admin-dashboard"
                                active={router === '/admin-dashboard'}>
                                <div className="py-2.5 space-y-1 capitalize">
                                    {t('dashboard')}
                                </div>
                            </ResponsiveNavLink>
                        ) : null
                    }

                    {/* Responsive Navigation Options */}
                    <div className="border-b border-gray-200">
                        {/* Daily Board Dropdown */}
                        {
                            (
                                includes(['master_admin', 'office_manager', 'dispatcher', 'salesperson'], user?.role)
                                || userHasPermissions(['daily-board-view', 'daily-board-update'])
                            ) ? (
                                <div className={`${openResDailyBoardButton ? 'border-b border-gray-100' : 'border-b-0'}`}>
                                    <ResponsiveNavLink
                                        active={router === '/' || router === '/sales-order' || router === '/purchase-order'}
                                        onClick={() => setOpenResDailyBoardButton(open => !open)}>
                                        <div className="py-2.5 mr-3 space-y-1 capitalize">
                                            {t('daily_board')}
                                            <div className="float-right">
                                                <i className={`fa-solid fa-chevron-down ease-in-out duration-300 ${openResDailyBoardButton ? 'rotate-180' : 'rotate-0'}`}></i>
                                            </div>
                                        </div>
                                    </ResponsiveNavLink>
                                    {openResDailyBoardButton && (
                                        <>
                                            <div className={`border-l-4 ${getResMenuItemBorderColor('/sales-order')}`}>
                                                <ResponsiveNavLink
                                                    to="/sales-order"
                                                    active={router === '/sales-order'}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize" onClick={() => setFocusedResMenuItem('/sales-order')}>
                                                        <span style={{ width: '20px', transform: 'rotate(45deg)', color: '#336195' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-arrow-up-long"></i>
                                                        </span>
                                                        {t('sales_orders')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                            <div className={`border-l-4 ${getResMenuItemBorderColor('/purchase-order')}`}>
                                                <ResponsiveNavLink
                                                    to="/purchase-order"
                                                    active={router === '/purchase-order'}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize" onClick={() => setFocusedResMenuItem('/purchase-order')}>
                                                        <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-arrow-up-long"></i>
                                                        </span>
                                                        {t('purchase_orders')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                            {
                                                (
                                                    includes(['master_admin', 'office_manager'], user?.role)
                                                    || userHasPermissions(['daily-board-view', 'daily-board-update'])
                                                ) ? (
                                                    <div className={`border-l-4 ${getResMenuItemBorderColor('/broker-transactions')}`}>
                                                        <ResponsiveNavLink
                                                            to="/broker-transactions"
                                                            active={router === '/broker-transactions'}>
                                                            <div className="py-2.5 flex items-center justify-start gap-3 capitalize" onClick={() => setFocusedResMenuItem('/broker-transactions')}>
                                                                <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                                                    <i className="fa-solid fa-repeat"></i>
                                                                </span>
                                                                {t('broker_transactions')}
                                                            </div>
                                                        </ResponsiveNavLink>
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    )}
                                </div>
                            ) : null
                        }

                        {/* Customer Portal Dashboards Dropdown */}
                        {
                            (
                                includes(['customers_user'], user?.role)
                            ) ? (
                                <div className={`${openResDashboardsButton ? 'border-b border-gray-100' : 'border-b-0'}`}>
                                    <ResponsiveNavLink
                                        active={router === '/shipments-dashboard' || router === '/collections-dashboard'}
                                        onClick={() => setOpenResDashboardsButton(open => !open)}>
                                        <div className="py-2.5 mr-3 space-y-1 capitalize">
                                            {t('dashboards')}
                                            <div className="float-right">
                                                <i className={`fa-solid fa-chevron-down ease-in-out duration-300 ${openResDashboardsButton ? 'rotate-180' : 'rotate-0'}`}></i>
                                            </div>
                                        </div>
                                    </ResponsiveNavLink>
                                    {openResDashboardsButton && (
                                        <>
                                            <div className={`border-l-4 ${getResMenuItemBorderColor('/shipments-dashboard')}`}>
                                                <ResponsiveNavLink
                                                    to="/shipments-dashboard"
                                                    active={router === '/shipments-dashboard'}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize" onClick={() => setFocusedResMenuItem('/shipments-dashboard')}>
                                                        <span style={{ width: '20px', transform: 'rotate(45deg)', color: '#336195' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-arrow-up-long"></i>
                                                        </span>
                                                        {t('shipments_board')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                            <div className={`border-l-4 ${getResMenuItemBorderColor('/collections-dashboard')}`}>
                                                <ResponsiveNavLink
                                                    to="/collections-dashboard"
                                                    active={router === '/collections-dashboard'}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize" onClick={() => setFocusedResMenuItem('/collections-dashboard')}>
                                                        <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-arrow-up-long"></i>
                                                        </span>
                                                        {t('collections_board')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : null
                        }

                        {/* Customer Portal Request Service Dropdown */}
                        {
                            (
                                includes(['customers_user'], user?.role)
                            ) ? (
                                <div className={`${openResRequestButton ? 'border-b border-gray-100' : 'border-b-0'}`}>
                                    <ResponsiveNavLink
                                        active={router === '/requests'}
                                        onClick={() => setOpenResRequestButton(open => !open)}>
                                        <div className="py-2.5 mr-3 space-y-1 capitalize">
                                            {t('request_service')}
                                            <div className="float-right">
                                                <i className={`fa-solid fa-chevron-down ease-in-out duration-300 ${openResRequestButton ? 'rotate-180' : 'rotate-0'}`}></i>
                                            </div>
                                        </div>
                                    </ResponsiveNavLink>
                                    {openResRequestButton && (
                                        <>
                                            <div className={`border-l-4 border-gray-300`}>
                                                <ResponsiveNavLink
                                                    onClick={handleOpenRS}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize">
                                                        <span style={{ width: '20px', transform: 'rotate(45deg)', color: '#336195' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-arrow-up-long"></i>
                                                        </span>
                                                        {t('request_delivery')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                            <div className={`border-l-4 border-gray-300`}>
                                                <ResponsiveNavLink
                                                    onClick={handleOpenRC}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize">
                                                        <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-arrow-up-long"></i>
                                                        </span>
                                                        {t('request_collection')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                            <div className={`border-l-4 ${getResMenuItemBorderColor('/requests')}`}>
                                                <ResponsiveNavLink
                                                    to="/requests"
                                                    active={router === '/requests'}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize" onClick={() => setFocusedResMenuItem('/requests')}>
                                                        <span style={{ width: '20px', color: 'green' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-check"></i>
                                                        </span>
                                                        {t('my_requests')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : null
                        }

                        {/* Customer Portal Activity Details Dropdown */}
                        {
                            (
                                includes(['customers_user'], user?.role)
                            ) ? (
                                <div className={`${openResActivityButton ? 'border-b border-gray-100' : 'border-b-0'}`}>
                                    <ResponsiveNavLink
                                        active={router === '/proceeded-shipments' || router === '/proceeded-collections'}
                                        onClick={() => setOpenResActivityButton(open => !open)}>
                                        <div className="py-2.5 mr-3 space-y-1 capitalize">
                                            {t('activity_details')}
                                            <div className="float-right">
                                                <i className={`fa-solid fa-chevron-down ease-in-out duration-300 ${openResActivityButton ? 'rotate-180' : 'rotate-0'}`}></i>
                                            </div>
                                        </div>
                                    </ResponsiveNavLink>
                                    {openResActivityButton && (
                                        <>
                                            <div className={`border-l-4 ${getResMenuItemBorderColor('/proceeded-shipments')}`}>
                                                <ResponsiveNavLink
                                                    to="/proceeded-shipments"
                                                    active={router === '/proceeded-shipments'}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize" onClick={() => setFocusedResMenuItem('/proceeded-shipments')}>
                                                        <span style={{ width: '20px', transform: 'rotate(45deg)', color: '#336195' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-arrow-up-long"></i>
                                                        </span>
                                                        {t('shipments')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                            <div className={`border-l-4 ${getResMenuItemBorderColor('/proceeded-collections')}`}>
                                                <ResponsiveNavLink
                                                    to="/proceeded-collections"
                                                    active={router === '/proceeded-collections'}>
                                                    <div className="py-2.5 flex items-center justify-start gap-3 capitalize" onClick={() => setFocusedResMenuItem('/proceeded-collections')}>
                                                        <span style={{ width: '20px', transform: 'rotate(225deg)', color: '#B7472A' }} className="flex items-center justify-center">
                                                            <i className="fa-solid fa-arrow-up-long"></i>
                                                        </span>
                                                        {t('collections')}
                                                    </div>
                                                </ResponsiveNavLink>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : null
                        }

                        {/* Map */}
                        {
                            (
                                includes(['master_admin', 'office_manager', 'dispatcher'], user?.role)
                                || userHasPermissions(['map'])
                            ) ? (
                                <ResponsiveNavLink
                                    to="/map"
                                    active={router === '/map'}>
                                    <div className="py-2.5 space-y-1 capitalize">
                                        {t('map')}
                                    </div>
                                </ResponsiveNavLink>
                            ) : null
                        }

                        {
                            (
                                includes(['master_admin', 'office_manager', 'dispatcher'], user?.role)
                                || userHasPermissions(['dispatch'])
                            ) ? (
                                <ResponsiveNavLink
                                    to="/dispatch"
                                    active={router === '/dispatch'}>
                                    <div className="py-2.5 space-y-1 capitalize">
                                        {t('dispatch')}
                                    </div>
                                </ResponsiveNavLink>
                            ) : null
                        }

                        {/* Available Loads */}
                        {
                            (
                                includes(['driver', 'forklift_driver'], user?.role)
                            ) ? (
                                <ResponsiveNavLink
                                    to="/available-loads"
                                    active={router === '/available-loads'}>
                                    <div className="py-2.5 space-y-1 capitalize">
                                        {t('available_loads')}
                                    </div>
                                </ResponsiveNavLink>
                            ) : null
                        }

                        {/* Reports */}
                        {
                            (
                                includes(['master_admin', 'office_manager'], user?.role)
                            ) ? (
                                <ResponsiveNavLink
                                    to="/reports"
                                    active={router === '/reports'}>
                                    <div className="py-2.5 space-y-1 capitalize">
                                        {t('reports')}
                                    </div>
                                </ResponsiveNavLink>
                            ) : null
                        }
                    </div>

                    {/* Responsive Settings Options */}
                    <div className="border-b border-gray-200">
                        <ResponsiveNavButton
                            onClick={() => setOpenResSettingsButton(open => !open)}>
                            <div className='flex justify-between items-center mr-3'>
                                <div className='flex items-center py-2.5 capitalize'>
                                    <div className="flex-shrink-0">
                                        <svg
                                            className="h-10 w-10 fill-current text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                            />
                                        </svg>
                                    </div>

                                    <div className="ml-3">
                                        <div className="font-medium text-base text-gray-800">
                                            {user?.name}
                                        </div>
                                        <div className="font-medium text-sm text-gray-500">
                                            {user?.email}
                                        </div>
                                    </div>
                                </div>
                                <i className={`fa-solid fa-chevron-down ease-in-out duration-300 ${openResSettingsButton ? 'rotate-180' : 'rotate-0'}`}></i>
                            </div>
                        </ResponsiveNavButton>

                        {/* Authentication */}
                        {openResSettingsButton && (
                            <div className='border-l-4 border-gray-300'>
                                {ResDropdownSignatureButton()}
                                {ResDropdownLogoutButton()}
                            </div>
                        )}
                    </div>
                </div>
            )}

            <UserSignatureModal open={userSignatureModalOpen} setOpen={setUserSignatureModalOpen} userId={user?.id} userHasSignature={userHasSignature} setUserHasSignature={setUserHasSignature} />
        </nav>
    )
}

export default Navigation
